<template>
  <div class="container">
    <div class="text-center my-5">
      <h1 class="display-3 fs-1 fw-lighter text-success text-bold">QRyourWIFI</h1>
      <p v-show="!printing">Create a QR code with your wifi credentials to give guests easy access without sharing a password. </p>
    </div>
    <div class="row my-5">
      <div class="col">
        <div id="networkEncryptionOptions"
            v-show="!printing"
            class="">
             <h4>Choose your network's encryption type:</h4>
          <div
              @change="renderQRCode()"
              mandatory
              class="form-check"
          >
           
            <div class="text-start">
              <div class="form-check">
                  <input type="radio"
                        label="WEP"
                        value="WEP"
                        id="WEPSecurity"
                        v-model="network_encryption"
                  >
                  <label class="form-check-label mx-2" for="WEPSecurity">WEP</label>
              </div>
              <div class="form-check">
                  <input type="radio"
                        label="WPA/WPA2"
                        value="WPA"
                        id="WPASecurity"
                        v-model="network_encryption"
                  >
                  <label class="form-check-label mx-2" for="WPASecurity">WPA/WPA2 (Most common)</label>
              </div>
              <div class="form-check">
                  <input type="radio"
                      label="No password"
                      value="nopass"
                      id="NoSecurity"
                      v-model="network_encryption"
                  >
                  <label class="form-check-label mx-2" for="NoSecurity">No password</label>
              </div>
            </div>
          </div>
        </div>
        </div>
    </div>

     <div class="row my-5">
      <div class="col">
        <div class="align-self-center">
        <h4 v-show="!printing">Enter the name of your WIFI network:</h4>   
                  <input
                  label="Wifi name"
                  class="wifi-input"
                  placeholder="WIFI Network Name"
                  maxlength="32"
                  v-model="ssid"
                  counter="32"
                  required
                  id="ssid"
                  @change="renderQRCode()"
                  >
              </div>
          </div>

      <div class="col">
        <div class="text-start" v-show="!printing">
          <h4>Enter your network password:</h4>
            <input
                class="wifi-input"
                label="Wifi password"
                placeholder="WIFI Network Password"
                v-model="password"
                id="password"
                v-show="!printing"
                @change="renderQRCode()"
            >
        </div>
      </div>
      </div>

    <div
        @input="renderQRCode()"
    >
    <div class="row">
      <div class="col text-center">
        <VueQRCode
            id="qr-code"
            :value="value"
            errorCorrectionLevel="H"
            
        />
      </div>
    </div>


    <div id="printingOptions" class="row">
      <div
          class="col text-center"
      >
        <v-switch
            label="Hide password on printing ?"
            v-model="hidePassword"
            hide-details
            class="py-4"
            v-show="!printing">
        </v-switch>

        <button
            class="btn btn-success btn-lg"
            v-on:click="print"
            v-show="!printing"
        >
          Print
        </button>
      </div>
    </div>




    <div class="row my-5">
      <div class="col">
        <h4 class="text-success">Why should I create a QR code for my WIFI network?</h4>
        <p>Generating a QR code for your WIFI has several benefits which can enhance the security of your home network.</p>
        <ul>
          <li>Never having to share a plain text password with guests.</li>
          <li>Ability to create a long password or passphrase that does not have to be easily rememberable.</li>
          <li>Perfect for short term guests, vacation rental properties like AirBNB or VRBO, etc.</li>
          <li>Easily print the QR code to display anywhere in your house!</li>
        </ul>
      </div>
    </div>
      

    


    

      


    </div>



        </div>

  
</template>

<script>
import VueQRCode from 'vue-qrcode'

export default {
    components: {
        VueQRCode
    },
    data() {
        return {
            ssid: '',
            password: '',
            network_encryption: "WPA",
            hidePassword: false,
            printing: false,
            value: "WIFI:T:WPA;S:;T:;;"
        }
    },
    methods: {
        renderQRCode() {
            this.value = 
                "WIFI:T:" + this.network_encryption +
                ";S:" + this.ssid +
                ";P:" + this.password +
                ";;";
        },
        print() {
            if (!(this.ssid.length > 0)) return alert("Wifi name cannot be empty!");
            if (this.network_encryption === "WPA" && !(this.password.length >= 8))
                return alert("Password must be at least 8 characters long!");
            if (this.network_encryption === "WEP" && !(this.password.length >= 6))
                return alert("Password must be at least 6 characters long!");
            this.printing = true;
            this.$nextTick(function() {
                window.print();
                this.printing = false;
            });
        }
    }

}

</script>

<style>

#qr-code {
    height: 400px;
    width: 400px;
}

.wifi-input {
  border: none;
  border-bottom: 1px solid black;
  width: 80%;
}

</style>


